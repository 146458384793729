import React, { useContext, useState } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../contexts/AppContext';
import { Link } from 'gatsby';


export default function NavBack() {
    const { appState } = useContext(AppContext);
    const [clicked, setClicked] = useState(false);


    return (
        <Link to="/"  className="nav-back-btn">
            <FontAwesomeIcon className="navback-chevron-icon" icon={faChevronLeft} />
            <span className="navback-label">{appState.i18n.navs.back}</span>
        </Link>
    )
}
